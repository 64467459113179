import React, { useState, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import AXIOS from "../../../axios-config";
import cogoToast from "cogo-toast";

import Layout from "../../components/motorrad/layout";
import SEO from "../../components/motorrad/seo";
import ImageBackground from "../../components/imagebackground";
import CustomMotorSchoolCard from "../../components/motorrad/custommotorschoordcard";
import Spinner from "../../components/spinner";
import settings from "../../../settings";
import ReCAPTCHA from 'react-google-recaptcha';

const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/motorrad/touring/bmw-moto-tours-banner.jpg`;
const topbannerMobile = `${settings.IMAGES_BASE_URL}/images/motorrad/touring/bmw-moto-tours-banner.jpg`;
const motortour = `${settings.IMAGES_BASE_URL}/images/motorrad/touring/bmw-custom-moto-tours.jpg`;

const Touring  = () =>  {

  const [isLoading, setIsLoading] = useState(false)
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const contentRef = useRef(null);

  const initialValues = {
    firstname: "",
    lastname: "",
    organization: "",
    zipcode: "",
    email: "",
    address: "",
    city: "",
    state: "",
    phone: "",
    attendees: "",
    event_date: "",
    selectedTour: [],
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("Please enter your first name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    lastname: Yup.string()
      .required("Please enter your last name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    organization: Yup.string()
      .required("Please enter your organization")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    address: Yup.string()
      .required("Please enter your address")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    city: Yup.string()
      .required("Please enter your city")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    state: Yup.string()
      .required("Please enter your state")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    zipcode: Yup.string()
      .required("Please enter your state")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    attendees: Yup.number()
      .typeError("No of attendees must be a number")
      .positive("No of attendees must be greater than zero")
      .required("No of attendees is required")
      .min(1, "No of attendees must be greater than 0")
      .max(8, "No of attendees cannot be more than 8"),
    event_date: Yup.date().required("Please choose your event date"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter a valid phone number"
      ),
    selectedTour: Yup.array().required(
      "Please select at least one of the tours"
    ),
  });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true)
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return;
    }

    AXIOS.post("/api/mototour", values,{headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        });
      });
  };

  const scrollToContent = () => {
    if(!contentRef) return;

    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

    return (
      <Layout>
        <SEO
          title="The BMW Rider Academy School | Touring"
          description="BMW motorrad training includes the basics, street survival, advanced on-road training, a USRA Authority course and even women's on and off-road classes!"
        />
        <div className="touring">
          <ImageBackground
            imageDesktop={topbannerDesktop}
            imageMobile={topbannerMobile}
            imageAlt="BMW Touring Private Top Banner"
            scrollTo={scrollToContent}
          />
          <div className="container" ref={contentRef}>
            <div className="mototours__header">
              <h1>Moto tours</h1>
              <p>
                Let's go for a ride. Moto tours give you the opportunity to take
                a ride through various scenic locations with fellow riders while
                enjoying the Ultimate Riding Machine®.
              </p>
              <br/>
              <p>
                These are all-inclusive tours, and once you arrive at the Performance Center in your riding 
                gear, we take over from there. They include use of a BWW motorcycle, lodging, food, fuel and 
                snacks. Lodging utilizes mountain lodges and local dining, with friendly staff and amazing 
                food. During the evening hours, relax by fire pits with new friends or book a private tour 
                for your own group.
              </p>
            </div>
          </div>
          <div className="motortours">
            <CustomMotorSchoolCard
              rowType="row"
              image={motortour}
              imageAlt="Smoky Mountain Twisted Tours"
              header="CUSTOM MOTO TOURS"
            >
              <h6>Smoky Mountain Twisted Tours </h6>
              <p>
                Ride through the Smoky Mountains, curving through iconic roadways and exploring twisted 
                back roads known only to locals. You’ll cover multiple states (North and South Carolina, 
                and Tennessee) while enjoying the feel of a BMW motorcycle. Explore our diverse range of 
                BMW motorcycles, including sleek street models and the adventurous GS line, providing 
                options for every rider’s preference. 
              </p>

              <h6>Smoky Mountain Adventure Tours</h6>
              <p>
                A combination of on- and off-road, these tours cover four states and include the iconic 
                twisted roadways of the Smoky Mountains, along with a great mix of off-road adventure 
                riding on your choice of our BMW GS Adventure bikes.
              </p>
              <br/>
              <p>
                Two, three, four and five-day tours are available. There is a minimum requirement of four 
                and a maximum of ten riders for each tour. You must use a school-provided bike, and no 
                passengers are allowed. 
              </p>
              <br/>
              <p>
                If you want to book beyond five days or have more than ten riders, please call us at 888-345-4269 and we’ll create a custom program for you.
              </p>
              <br/>
              <p class="cost">Cost per person:</p>
              <p>Two-Day – $2,450</p>
              <p>Three-Day – $3,900</p>
              <p>Four-Day – $5,450</p>
              <p>Five-Day – $6,900</p>
              <p></p>
            </CustomMotorSchoolCard>
          </div>
          <section className="getstarted">
            <div className="container">
              <div className="getstarted__header">
                <h2>READY TO GET STARTED?</h2>
                <p>
                  If you’re interested in a private Moto Tour, please fill out
                  the form below and a Motorrad Instructor will contact you
                  directly.
                </p>
                <p>
                  {" "}
                  If you have a specific date in mind, please let us know, and
                  we will do our best to accommodate you.
                </p>
              </div>
              <div className="getstarted__content">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="checkbox">
                        <h4>Select Tour</h4>
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="selectedTour"
                            value="Twisted Tour"
                            aria-label="twisted_tour"
                          />
                          <label htmlFor="twisted">Twisted Tour </label>
                        </div>
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="selectedTour"
                            value="Adventure Tour"
                            aria-label="adventoure_tour"
                          />
                          <label htmlFor="adventure">Adventure Tour</label>
                        </div>
                        {errors.selectedTour && touched.selectedTour ? (
                          <span className="input-feedback">
                            {errors.selectedTour}
                          </span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                      <div className="input">
                        <h4>Information</h4>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="firstname">First Name</label>
                            <Field
                              aria-label="firstname"
                              type="text"
                              name="firstname"
                              className={
                                touched.firstname && errors.firstname
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.firstname && touched.firstname ? (
                              <span className="input-feedback">
                                {errors.firstname}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="lastname">Last Name</label>
                            <Field
                              aria-label="lastname"
                              type="text"
                              name="lastname"
                              className={
                                touched.lastname && errors.lastname
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.lastname && touched.lastname ? (
                              <span className="input-feedback">
                                {errors.lastname}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="organization">Organization</label>
                            <Field
                              aria-label="organization"
                              type="text"
                              name="organization"
                              className={
                                touched.organization && errors.organization
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.organization && touched.organization ? (
                              <span className="input-feedback">
                                {errors.organization}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="email">Your Email</label>
                            <Field
                              aria-label="email"
                              type="email"
                              name="email"
                              className={
                                touched.email && errors.email
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.email && touched.email ? (
                              <span className="input-feedback">
                                {errors.email}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="input__group">
                          <label htmlFor="address">Address</label>
                          <Field
                            aria-label="address"
                            type="text"
                            name="address"
                            className={
                              touched.address && errors.address
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.address && touched.address ? (
                            <span className="input-feedback">
                              {errors.address}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="city">City</label>
                            <Field
                              aria-label="city"
                              type="text"
                              name="city"
                              className={
                                touched.city && errors.city ? "has-error" : null
                              }
                            />
                            {errors.city && touched.city ? (
                              <span className="input-feedback">
                                {errors.city}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group input--sm">
                            <label htmlFor="state">State</label>
                            <Field
                              aria-label="state"
                              type="text"
                              name="state"
                              className={
                                touched.state && errors.state
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.state && touched.state ? (
                              <span className="input-feedback">
                                {errors.state}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="zipcode">Zip Code</label>
                            <Field
                              aria-label="zipcode"
                              type="text"
                              name="zipcode"
                              className={
                                touched.zipcode && errors.zipcode
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.zipcode && touched.zipcode ? (
                              <span className="input-feedback">
                                {errors.zipcode}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="phone">Phone</label>
                            <Field
                              aria-label="phone"
                              type="tel"
                              name="phone"
                              className={
                                touched.phone && errors.phone
                                  ? "has-error"
                                  : null
                              }
                            />
                            {errors.phone && touched.phone ? (
                              <span className="input-feedback">
                                {errors.phone}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="eventDate">Event Date</label>
                            <Field aria-label="event_date" type="date" name="event_date" />

                            {errors.event_date && touched.event_date ? (
                              <span className="input-feedback">
                                {errors.event_date}
                              </span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="input__group">
                          <label htmlFor="attendees">
                            Number of Attendees (Maximum of 8)
                          </label>
                          <Field
                            aria-label="attendees"
                            type="text"
                            name="attendees"
                            className={
                              touched.attendees && errors.attendees
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.attendees && touched.attendees ? (
                            <span className="input-feedback">
                              {errors.attendees}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="notes">Additional Notes</label>
                            <Field aria-label="notes" as="textarea" name="notes" />
                          </div>
                        </div>
                      </div>
                      <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
                      <button
                        className="btn btn-bordered"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isLoading ? <Spinner /> : "Submit"}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
}

export default Touring;
